import React, { useState, useEffect, Fragment } from 'react'
import CollectionCard from './CollectionCard'
import OnPageEvents from '../../helpers/OnPageEvents'

const CollectionList = (props) => {
  const [collections, setCollections] = useState(props.collections)

  useEffect(() => {
    function handleCollectionCreated (event) {
      const newCollection = { name: event.detail.name, path: event.detail.path }

      setCollections(collections.concat([newCollection]))
    }

    OnPageEvents.addEventListener('collectionCreated', handleCollectionCreated)

    return () => {
      OnPageEvents.removeEventListener('collectionCreated', handleCollectionCreated)
    }

  })

  const List = () => {
    return (
      <ul className='Variable-column'>
        {
          collections.map((collection, i) => { return ( <CollectionCard key={i} name={collection.name} path={collection.path}/> ) })
        }
      </ul>
    )
  }

  const EmptyState = () => {
    return (
      <p>
        You do not have any collections. Add a new collection to organise your bookmarks.
      </p>
    )
  }

  return (
    <Fragment>
      {
        collections.length > 0
          ? <List/>
          : <EmptyState/>
      }
    </Fragment>
  )
}

CollectionList.defaultProps = {
  collections: []
}

export default CollectionList