import React, { Component } from 'react'
import railsCsrfToken from '../helpers/RailsCsrfToken'

const withCollectionBookmarkApi = (WrappedComponent) => {
  return class extends Component {

    getCollectionDetailsForBookmark = (bookmarkId) =>{
      const { collectionBookmarksIndexUrl } = this.props
      return fetch(collectionBookmarksIndexUrl, {
        method: 'POST',
        body: JSON.stringify({ bookmark_id: bookmarkId }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    updateCollectionBookmarks = (bookmarkId, collectionDetails) => {
      const { updateCollectionBookmarksUrl } = this.props
      return fetch(updateCollectionBookmarksUrl, {
        method: 'POST',
        body: JSON.stringify({ bookmark_id: bookmarkId, collection_details: collectionDetails }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent { ...this.props }
          getCollectionDetailsForBookmark={ this.getCollectionDetailsForBookmark }
          updateCollectionBookmarks={ this.updateCollectionBookmarks }
        />
      )
    }
  }
}

export default withCollectionBookmarkApi