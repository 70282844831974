import React, {Component, Fragment} from "react"

class PasswordField extends Component {

  state = {
    contentsVisible: false
  }

  render = () => {
    const { className, caption, errors, name, id, describedby} = this.props
    const contentsVisible = this.state.contentsVisible
    const fieldType = contentsVisible ? "text" : "password"
    const hasErrors = errors.length > 0
    const toggleVisibility = () => this.setState({ contentsVisible: !contentsVisible })

    return (
      <Fragment>
        <div className={hasErrors ? "field_with_errors" : null}>
          <input
            className={className}
            required="required"
            marked="true"
            caption={caption}
            errors={errors}
            type={fieldType}
            name={name}
            id={id}
            aria-describedby={`${describedby} ${hasErrors ? id+"_error" : null}`} />
          <div className="fr relative mt--2rem mr3" onClick={toggleVisibility}>
            <i className={contentsVisible ? "fa fa-eye blue" : "fa fa-eye-slash grey"}></i>
          </div>
          {hasErrors && <p className="guardsman-red bn f-0-75rem mb0" id={id+"_error"}>Password {errors}</p>}
        </div>
      </Fragment>
    )
  }
}

export default PasswordField