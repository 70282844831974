import React, { Component } from 'react'
import asBookmarkable from '../HOC/asBookmarkable'
import withBookmarkApi from '../HOC/withBookmarkApi'
import AsOpenable from '../HOC/AsOpenable'
import Modal from 'react-modal'

Modal.setAppElement('#main-content');

class Bookmark extends Component {
  constructor(props) {
    super(props);
  }
  
  addBookmark = () => {
    const { bookmark, resourceId } = this.props
    bookmark(resourceId)
  }

  removeBookmark = () => {
    const { close, unbookmark, resourceId } = this.props
    unbookmark(resourceId)
    close()
  }

  render = () => {
    const { open, isOpen, close, bookmarked, ariaLabel, className } = this.props

    return (
      <div className={`${className}`}>
        <form className='flex flex-column just items-end' onSubmit={(e) => e.preventDefault()}>
          <button tabIndex={ 0 } aria-pressed={ bookmarked ? 'true' : 'false' } onClick={ bookmarked ? open : this.addBookmark}
          aria-label={`${ bookmarked ? 'Remove bookmark for' : 'Bookmark' } this resource: ${ ariaLabel }`} 
          className='Star-bookmark__button' type="submit" value="Submit">
            <span className='Star-bookmark__text'>{ bookmarked ? 'Remove bookmark' : 'Bookmark resource' }</span>
            <i className={`transition-24 fa-bookmark ${ bookmarked ? 'fas supernova' : 'far' }`}></i>
          </button>
          <div role='tooltip' tabIndex={ -1 } aria-hidden='true' className='dn db-l Star-bookmark__tooltip nunito-font'>
            { bookmarked ? 'Remove bookmark' : 'Bookmark this resource' }
          </div>
        </form>

        <Modal isOpen={isOpen} 
          onRequestClose={close}
          className="Modal pb-1-5rem pb4-ns"
          overlayClassName='Backdrop'
          contentLabel="Confirm Remove Bookmark"
        >
          <h2>Remove bookmark</h2>
          <p>This resource will no longer be saved in any collections.</p>
          <div className="flex justify-between mt4">
            <button className="button--text" onClick={close}>Cancel<span></span></button>
            <button className="button--text" onClick={this.removeBookmark}>Remove<span></span></button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default AsOpenable(withBookmarkApi(asBookmarkable(Bookmark)))
