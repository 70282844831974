import React, { Fragment } from 'react'
import AsOpenable from '../../HOC/AsOpenable'
import Modal from 'react-modal'

const WarningModalSubmit = (props) => {
  const { open, close, isOpen, form, contentLabel, modalHeading, modalCopy, cancelCopy, submitCopy, confirmCopy, disabled } = props

  return (
    <Fragment>
      <button onClick={open} disabled={disabled}>{submitCopy}</button>
      <Modal isOpen={isOpen} 
      onRequestClose={close}
      className="Modal Modal--warning pb-1-5rem pb4-ns"
      overlayClassName='Backdrop'
      contentLabel={contentLabel}
      >
      <h2>{modalHeading}</h2>
      <p>{modalCopy}</p>
      <div className="flex flex-column-reverse flex-row-ns justify-between mt3">
        <button className="button--outlined button--outlined__modal--option options mr3-ns" onClick={close}>{cancelCopy}</button>
        <input className="button--outlined button--outlined__modal--option options mb3 mb0-ns" type="submit" form={form} value={confirmCopy}/>
      </div>
      </Modal>
    </Fragment>
  )
}

export default AsOpenable(WarningModalSubmit)