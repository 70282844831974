import React, { Component } from 'react'
import AsOpenable from '../../../HOC/AsOpenable'
import OutsideInteractionHandler from "../../../HOC/OutsideInteractionHandler"

const banner = document.getElementsByClassName("banner")[0]

class ResourcesNavMobile extends Component {
  constructor(props){
    super(props)
    this.observer = new IntersectionObserver(this.observeBanner, {})
    this.observer.observe(banner)
  }

  state = {
    bannerVisible: true,
  }

  observeBanner = (entries) => {
    this.setState({ bannerVisible: entries[0].isIntersecting })
  }

  render = () => {

    const { categoryTitle, currentResource, resources, isOpen, toggle, close } = this.props
    const { bannerVisible } = this.state

    return (
      <div className={`affix-to-top shadow-1 bg-navy pa3 ${bannerVisible ? 'dn' : 'db'} dn-l`}>
        <h4 className="white f5 lh-copy">{categoryTitle}</h4>
        <OutsideInteractionHandler onClickOutside={close} onFocusOutside={close}>
          <button onClick={toggle}
                  className="no-underline white pointer bg-transparent bn pa0 fw5 lh-copy w-100 button__focus--text button__focus--text--white"
                  aria-label="Open menu to see other resources in this category"
                  aria-haspopup="true"
                  aria-expanded={isOpen}
          >
            <div className=" flex justify-between w-100">
              {currentResource.title}<i className={`fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
            </div>
            <span></span>
          </button>
          {isOpen ? <ul className="list pa0 mt4">
            {
              resources.map((resource) => {
                return (
                  <li key={resource.id} className="mb2">
                    <a href={`${resource.showPath}`}
                       className={`${resource.title == currentResource.title ? "blue fw8" : "white"} link w-100 button__focus--text button__focus--text--white`}
                    >
                      {resource.title}
                      <span></span>
                    </a>
                  </li>
                )
              })
            }
          </ul>: ""}
        </OutsideInteractionHandler>
      </div>
    )
  }
}

export default AsOpenable(ResourcesNavMobile)