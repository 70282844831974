import React, { Component, Fragment } from "react"
import ResourceLinks from "./ResourceLinks"
import OpenAccountLinksButton from "./OpenAccountLinksButton"
import AccountLinks from "./AccountLinks"
import OutsideInteractionHandler from "../../../HOC/OutsideInteractionHandler"

class NavBar extends Component {

  state = { accountOptionsShowing: false }

  toggleAccountOptions = () => this.setState({ accountOptionsShowing: !this.state.accountOptionsShowing })

  hideAccountOptions = () => this.setState({ accountOptionsShowing: false })

  render () {
    const { navigationProps } = this.props
    const { accountOptionsShowing } = this.state
    return (
      <Fragment>
        <nav id='top-nav-bar' className='flex justify-between items-center pv-0-9375rem'>
          <ResourceLinks resourceLinks={navigationProps.resourceLinks} />
          <div className="relative">
            <OutsideInteractionHandler onClickOutside={this.hideAccountOptions} onFocusOutside={this.hideAccountOptions}>
              {
                navigationProps.userLoggedIn
                ? <OpenAccountLinksButton
                    toggleAccountOptions={this.toggleAccountOptions}
                    navigationProps={navigationProps}
                    accountOptionsShowing={this.state.accountOptionsShowing}
                  />
                : ""
              }  
              {
                accountOptionsShowing &&
                <AccountLinks accountLinks={navigationProps.accountLinks} />
              }
            </OutsideInteractionHandler>
          </div>
        </nav>
      </Fragment>
    )
  }
}
export default NavBar