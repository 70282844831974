import React, { Component, Fragment } from "react"
import AsOpenable from '../HOC/AsOpenable'

class PlanDetails extends Component {
  render () {
    const { title, teamSize, courses, isOpen, toggle } = this.props

    return (
      <Fragment>
        <div className={`details ${isOpen && 'details--purple'} transition-24`} aria-label="Expand more plan details">
          <div className='details__brief bt bn-l b--eastern-breeze flex items-center justify-between'>
            <div>
              <h2 className='details__brief__heading'>Your Plan</h2>
              <p>{title}</p>
              <p>Team Size - {teamSize}</p>
            </div>
            <i className={`dn-l fas ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`} onClick={toggle}></i>
          </div>
          <div className={`details__elaborate ${isOpen && "details__elaborate--expanded"}`}>
            <h3 className='details__elaborate__heading'>Inclusion Courses</h3>
            {courses ?
              <ul className='list'>
                {courses.map((course) => {
                  return (
                    <li key={course.title} className='list__item list__item--with-image'>
                      <img src={course.icon_url}></img>
                      <span>{course.title}</span>
                    </li>
                  )
                })}
              </ul>
              :
              <p>No courses for your current plan</p>
            }
            <h3 className='details__elaborate__heading'>Resources</h3>
            <ul className='list'>
              <li className='list__item list__item--bullets'>+75 cultural profiles</li>
              <li className='list__item list__item--bullets'>8 Major Religious profiles</li>
            </ul>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default AsOpenable(PlanDetails)