import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify';

// Important, this component should be rendered at all times and only one instance should exist, 
// currently being rendered in application.haml

class ToastNotificationContainer extends Component {

  render = () => {
    return (
      <ToastContainer
        position="bottom-center"
        autoClose={900000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        role="alert"
        pauseOnFocusLoss
        draggable
        pauseOnHover
        pauseOnFocusLoss
        limit={3}
        />
    )
  }
}

export default ToastNotificationContainer