import React, { Component } from 'react'
import ManageBookmark from './forms/ManageBookmark.js'
import ManageBookmarkIcon from './ManageBookmarkIcon.js'

import AsOpenable from '../HOC/AsOpenable'
import Modal from 'react-modal'

Modal.setAppElement('#main-content');

class ManageBookmarkBtn extends Component {

  constructor(props) {
    super(props);
    this.manageBookmarkRef = React.createRef();
    this.state = {
      hover: false
    }
  }

  render = () => {

    const { open, isOpen, close, ariaLabel, bookmarkId, collectionBookmarksIndexUrl, updateCollectionBookmarksUrl } = this.props
    const { hover } = this.state

    return (
      <div className='Manage-bookmark'>
        <form className='flex flex-column just items-end' onSubmit={(e) => e.preventDefault()}>
          <button tabIndex={ 0 }
          aria-label={`Add or remove ${ ariaLabel} to any collection.`}
          className='Manage-bookmark__button pointer'
          onMouseEnter={ () => this.setState({hover: true}) }
          onMouseLeave={ () => this.setState({hover: false}) }
          type="submit" 
          value="Submit"
          ref={ this.manageBookmarkRef }
          onClick={ open }>
            { hover ? <ManageBookmarkIcon fill='#D90D6C'/> : <ManageBookmarkIcon fill='#050840'/> }
          </button>
          <div role='tooltip' tabIndex={ -1 } aria-hidden='true' className='dn db-l Manage-bookmark__tooltip nunito-font'>
            Manage Bookmark
          </div>
        </form>
        <Modal
          isOpen={ isOpen }
          onRequestClose={ close }
          className='Modal'
          overlayClassName='Backdrop'
          contentLabel="Manage Bookmarks Modal"
          aria={{
            labelledby: "Manage Bookmarks Modal",
            describedby: "A pop up that allows adding and removing bookmarks from collections"
          }}>
            <ManageBookmark
              key={ bookmarkId }
              close={ close }
              bookmarkId={ bookmarkId }
              collectionBookmarksIndexUrl={ collectionBookmarksIndexUrl }
              updateCollectionBookmarksUrl={ updateCollectionBookmarksUrl }
              revertFocus={ this.manageBookmarkRef }
            />
        </Modal>
      </div>
    )
  }
}

export default AsOpenable(ManageBookmarkBtn)