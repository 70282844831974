import React, { Component } from 'react'

export default class Checkbox extends Component {

  checkboxRef = React.createRef()

  handleKeyPress = (event) => {
    const { handleClick, collection_id } = this.props
    if(event.key == " " || event.key == "Enter"){
      event.stopPropagation()
      event.preventDefault()
      handleClick(collection_id)
    }
  }

  render() {
    const { handleClick, collection: {name, selected}, collection_id } = this.props

    return (
      <div className='Manage-bookmark-form__underline Manage-bookmark-form__checkbox'>
        <input
          aria-pressed={ selected ? 'true' : 'false' }
          onChange={ () => { handleClick(collection_id) } }
          type="checkbox"
          id={ name }
          name={ name }
          value={ collection_id }
          checked={ selected }
          ref={this.checkboxRef}
        />
        <label htmlFor={ name } tabIndex={0} onKeyPress={this.handleKeyPress}>
          { name }
        </label>
    </div>
    )
  }
}
