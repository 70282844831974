import React from "react"
import NavLink from "../NavLink/NavLink"

export default function AccountLinks(props) {
  const { accountLinks } = props
  return (
    <div className="flex flex-column pt4 pb3">
    {
      accountLinks.map((link) => {
        return (
          <div key={link.text} className='Account-link'>
            <NavLink link={link} showActiveStyles = {false} />
          </div>
        )
      })
    }
  </div>
  )
}