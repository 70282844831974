import React from 'react'
import cn from 'classnames'

const CharacterLimit = (props) => {
  const { text, limit, errorMessage } = props
  const limitReached = () => { return text.length >= limit }

  return (
    <div className={cn({'flex justify-between Collection__limit-reached': limitReached()})}>
      <p className={cn({'dn': !limitReached()})}>
        {errorMessage}
      </p>
      <p className='tr mb4 Collection__char-count'>
        {text.length}/{limit}
      </p>
    </div>
  )
}

export default CharacterLimit