const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const checkForRecordInvalid = (response) => {
  if(!response.ok && response.status === 409) {
    throw Error('RecordInvalid');
  }

  return response
}

export default handleErrors