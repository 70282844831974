import React, { Component } from 'react';
import RenameCollectionButton from './RenameCollectionButton';
import DeleteCollectionButton from './DeleteCollectionButton'
import OutsideInteractionHandler from '../../HOC/OutsideInteractionHandler';

class CollectionOptionsEllipsis extends Component {

  state = { menuShowing: false }

  menuButton = React.createRef()

  toggleMenu = () => this.setState({ menuShowing: !this.state.menuShowing })

  hideMenu = () => this.setState({ menuShowing: false })

  render () {
    const { ...passThroughProps } = this.props

    const optionsMenu = (
      <ul id="collections_menu" role="menu" className="card--navigation card__drawer--navigation tr pa4 list" aria-labelledby="collections_menu_button">
        <li className="card__list--navigation" role="none">
          <RenameCollectionButton revertFocusRef={this.menuButton} { ...passThroughProps }></RenameCollectionButton>
        </li>
        <li className="card__list--navigation" role="none">
          <DeleteCollectionButton { ...passThroughProps }></DeleteCollectionButton>
        </li>
      </ul>
    )

    return (
        <div className="relative" id="collection_ellipsis_menu">
          <OutsideInteractionHandler
            onClickOutside={this.hideMenu} onFocusOutside={this.hideMenu}
          >
            <button
            id="collections_menu_button"
            role="button"
            ref={this.menuButton}
            aria-expanded={ this.state.menuShowing }
            aria-haspopup="true"
            aria-controls="collection_menu"
            aria-label="Collections Options Menu"
            className="button__icon"
            onClick={ this.toggleMenu }
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>

            { this.state.menuShowing && optionsMenu }
          </OutsideInteractionHandler>
        </div>
    )
  }
}
export default CollectionOptionsEllipsis