import React, { useState } from 'react'
import OnPageEvents from '../../helpers/OnPageEvents'

const HideEditorControls = () => {
  const [controlsHidden, setControlsHidden] = useState(false)

  const hideControls = () => {
    let controls = document.querySelectorAll('.cms-editor-controls')

    controls.forEach(control => {
      controlsHidden ? control.classList.remove('dn') : control.classList.add('dn')
    });

    let showControls = document.querySelectorAll('.show-edit-controls-icon')

    showControls.forEach(control => {
      if (controlsHidden) {
        control.classList.remove('fa-eye')
        control.classList.add('fa-eye-slash')
      } else {
        control.classList.remove('fa-eye-slash')
        control.classList.add('fa-eye')
      }
    });

    setControlsHidden(!controlsHidden)
    OnPageEvents.dispatchEvent('hideEditorControls', controls[0], {})
  }

  return (
    <button onClick={hideControls} className="button__icon">
      <i className="show-edit-controls-icon fa fa-eye-slash"></i>
    </button>
  )
}

export default HideEditorControls