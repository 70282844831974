import React, { useState, useEffect } from 'react'
import backToTopIcon from '../../images/ic_chevron_up.svg'
import cn from 'classnames'

const BackToTopButton = (props) => {
  const [isHidden, setIsHidden] = useState(true)

  const onBannerIntersectionObserved = (entries) => {
    setIsHidden(entries[0].isIntersecting)
  }

  const bannerIntersectionObserver = new IntersectionObserver(onBannerIntersectionObserved, { root: null })

  useEffect(() => {
    bannerIntersectionObserver.observe(document.querySelector(props.querySelector))
    return () => {
      bannerIntersectionObserver.unobserve(document.querySelector(props.querySelector))
    }
  }, [])

  const scrollToTopListener = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  return(
    <button onClick={scrollToTopListener} className={`btt-button ${cn({hidden: isHidden})}`}>
      <img src={backToTopIcon} className="btt-button--icon"></img>
    </button>
  )
}

export default BackToTopButton