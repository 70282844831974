import React, { Component } from 'react'

class ResourcesNavDesktop extends Component {

  render = () => {
    const { categoryTitle, currentResource, resources  } = this.props

    return (
      <div>
        <h4 className="f5 lh-copy nunito-font">{categoryTitle}</h4>
        <div className=" linebreak mt3 mb4 mw4"></div>
        {
          resources.map((resource) => {
            return (
              <li key={resource.id} className="mb3 list">
                <a
                  href={`${resource.showPath}?source=${currentResource.title}`}
                  className={`${resource.title == currentResource.title ? "pink fw8 disabled" : "copy"}
                    link w-100 button__focus--text button__focus--text--copy`
                  }
                >
                  {resource.title}
                </a>
              </li>
            )
          })
        }
      </div>
    )
  }
}

export default ResourcesNavDesktop
