import React, { Component } from 'react'
import railsCsrfToken from '../helpers/RailsCsrfToken'

const withCollectionApi = (WrappedComponent) => {
  return class extends Component {

    createCollection = (name) => {
      const { createCollectionUrl } = this.props
      return fetch(createCollectionUrl, {
        method: 'POST',
        body: JSON.stringify({ name: name }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    renameCollection = (name) => {
      const { renameCollectionUrl } = this.props
      return fetch(renameCollectionUrl, {
        method: 'PATCH',
        body: JSON.stringify({ name: name }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent { ...this.props }
          createCollection={ this.createCollection }
          renameCollection={ this.renameCollection }
        />
      )
    }
  }
}

export default withCollectionApi