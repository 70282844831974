import React, { Component, Fragment } from "react"
import AboutLinks from './AboutLinks'
import AsOpenable from '../../../../HOC/AsOpenable'
import OutsideInteractionHandler from "../../../../HOC/OutsideInteractionHandler"

class GuestNavBar extends Component {
  constructor(props) {
    super(props);
    this.hideMenuTimer = null;
    this.state = {
      active: false,
    }
  }

  componentDidMount(){
    this.checkActiveLink()
  }

  checkActiveLink = () => {
    this.props.navigationProps.aboutLinks.forEach(element => {
      element.path === window.location.href ? this.setState({active: true}) : ''
    })
  }

  closeWithDelay = () => {
    this.hideMenuTimer = setTimeout(() => {
      this.props.close();
    }, 1000);
    return () => clearTimeout(this.hideMenuTimer);
  }

  openMenu = () => {
    clearTimeout(this.hideMenuTimer);
    this.props.open();
  }

  render() {
    const { active } = this.state
    const { navigationProps, isOpen, close, toggle } = this.props

    return (
      <Fragment>
        <nav className="Guest-Nav containerStyle">
          <div onMouseLeave={this.closeWithDelay} onMouseEnter={this.openMenu}>
            <OutsideInteractionHandler onClickOutside={close} onFocusOutside={close}>
              <button onClick={toggle}
                className={`${active ? 'active-guest-link' : ''} ${isOpen ? 'open-guest-link' : ''} about-button-style hoverOnStyle no-underline navy pointer`}
                aria-label="Open the about menu for the incluude platform"
                aria-haspopup="true"
                aria-expanded={isOpen}
              >
                  About
                <i
                  className={`fas fa-chevron-down fill--navy ${isOpen? "open-animation pr2 pt1" : "close-animation pl2 pb1" } chevronStyle`}>
                </i>
              </button>
              {isOpen ?
                <div className={`navDivLinkStyle flex flex-column perspective bg-white drawer-height-open`}>
                  <AboutLinks aboutLinks={navigationProps.aboutLinks} customClasses="navLinkStyle"/>
                </div>
                : ""
              }
            </OutsideInteractionHandler>
          </div>
        </nav>
      </Fragment>
    )
  }
} export default AsOpenable(GuestNavBar)