import React from "react"

export default function NewTabNavLink(props) {
  const { link, optionalCustomClasses, optionalActiveStyles} = props
  return (
    <a key={ link.text }
      tabIndex = {0} 
      target='_blank' 
      rel='noopener noreferrer' 
      className={`${optionalCustomClasses} ${optionalActiveStyles} Navigation-link`} 
      href={ link.path }
      data-tracking-label={ link.dataTrackingLabel }>
      { link.text }
      <i className='far fa-external-link ml2'></i>
      <span className="ScreenReaderOnly">(opens in a new tab)</span>
    </a>
  )
}