import React, {Component } from "react";

class OutsideInteractionHandler extends Component {
  constructor(props) {
    super(props)
    this.interactiveBoundaryRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleFocusOutside = this.handleFocusOutside.bind(this)
  }

  componentDidMount() {
      document.addEventListener('mouseup', this.handleClickOutside);
      document.addEventListener('touchend', this.handleClickOutside);
      document.addEventListener('focusin', this.handleFocusOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mouseup', this.handleClickOutside);
      document.removeEventListener('touchend', this.handleClickOutside);
      document.removeEventListener('focusin', this.handleFocusOutside);
  }

  handleClickOutside = (event) => {
    const { onClickOutside } = this.props
    let path = event.composedPath()
    if(!this.isEventFromChild(path) && !this.isEventFromModal(path)){
      onClickOutside()
    }
  }

  handleFocusOutside = (event) => {
    const { onFocusOutside } = this.props
    let path = event.composedPath()
    if(!this.isEventFromChild(path) && !this.isEventFromModal(path)) {
      onFocusOutside()
    }
  }

  // This checks to see if the event has bubbled up from any thing inside of (and including) a react-modal
  isEventFromModal = (path) => {
    const reactModalPortals = Array.from(document.querySelectorAll('.ReactModalPortal'))
    return reactModalPortals.some(element => path.includes(element))
  }

  isEventFromChild = (path) => {
    // Check to see if this element is part of the event bubbling (sometimes will catch portaled elements).
    return !!path
      ? path.includes(this.interactiveBoundaryRef.current)
      : false;
  }

  render = () => {
    return (
      <div ref={this.interactiveBoundaryRef}>
        {this.props.children}
      </div>
    )
  }
}

export default OutsideInteractionHandler
