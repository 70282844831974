import React, { useState, useEffect } from 'react'
import OnPageEvents from '../../helpers/OnPageEvents'

const CollectionTitle = (props) => {
  const [name, setName] = useState(props.name || '')

  useEffect(() => {
    const handleRenamedCollection = (event) => {
      setName(event.detail.name)
    }

    OnPageEvents.addEventListener('renamedCollection', handleRenamedCollection)

    return () => {
      OnPageEvents.removeEventListener('renamedCollection', handleRenamedCollection)
    }
  })

  return (
    <h1 className='navy pb-0-5rem'>
    { name }
    </h1>
  )
}


export default CollectionTitle