import React from "react"

export default function UserName(props){
  const { navigationProps } = props
  return (
    <div className='Navigation-link Navigation-link--profile flex items-center pb4'>
      <i className='far fa-user-circle f3 pr3'></i>
    </div>
  )
}

