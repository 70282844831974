import React, { Component } from 'react'
import railsCsrfToken from '../helpers/RailsCsrfToken'

const withBookmarkApi = (WrappedComponent) => {
  return class extends Component {

    createBookmark = (resourceId) => {
      const { createBookmarkUrl } = this.props
      return fetch(createBookmarkUrl, {
        method: 'POST',
        body: JSON.stringify({ id: resourceId }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    deleteBookmark = (resourceId) => {
      const { deleteBookmarkUrl } = this.props
      return fetch(deleteBookmarkUrl, {
        method: 'DELETE',
        body: JSON.stringify({ id: resourceId }),
        credentials: 'same-origin',
        mode: 'cors',
        headers: {
          'X-CSRF-Token': railsCsrfToken(),
          'Content-Type': 'application/json'
        }
      })
    }

    render = () => {
      return (
        <WrappedComponent {...this.props} createBookmark={this.createBookmark} deleteBookmark={this.deleteBookmark} />
      )
    }
  }
}

export default withBookmarkApi
