import React from "react";

const ManageBookmarkIcon = (props) => {
  const { fill } = props
  return (
    <svg className="Manage-bookmark__icon" alt='' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.4002 0H4.8002C3.9178 0 3.2002 0.7176 3.2002 1.6V11.2C3.2002 12.0824 3.9178 12.8 4.8002 12.8H14.4002C15.2826 12.8 16.0002 12.0824 16.0002 11.2V1.6C16.0002 0.7176 15.2826 0 14.4002 0ZM4.8002 11.2V1.6H14.4002L14.4018 11.2H4.8002Z" fill={fill}/>
    <path d="M1.6 4.79995H0V14.4C0 15.2824 0.7176 16 1.6 16H11.2V14.4H1.6V4.79995ZM10.4 3.19995H8.8V5.59995H6.4V7.19995H8.8V9.59995H10.4V7.19995H12.8V5.59995H10.4V3.19995Z" fill={fill}/>
    </svg>
  )
}
export default ManageBookmarkIcon