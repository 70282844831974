import React from "react"
import NavLink from "../NavLink/NavLink"

export default function AccountLinks(props){
  const { accountLinks } = props
  return (
    <ul className="card--navigation card__drawer--navigation tr pa4 list" role="menu" aria-labelledby="account_options_menu">
    {
      accountLinks.map((link) => {
        return (
          <li key={link.text} role="none" className="card__list--navigation">
            <NavLink customClasses="Navigation-link--account-options" link={link} showActiveStyles = {false} />
          </li>
        )
      })
    }
    </ul>
  )
}