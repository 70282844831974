import React, { Component } from 'react'
import Modal from 'react-modal'
import railsCsrfToken from '../../helpers/RailsCsrfToken'

Modal.setAppElement('#main-content');

class DeleteCollection extends Component {

  render = () => {
    const { deleteCollectionUrl, close, isOpen } = this.props

    return(
      <Modal
        isOpen={ isOpen }
        onRequestClose={ close }
        shouldReturnFocusAfterClose={true}
        className='Modal'
        overlayClassName='Backdrop'
        contentLabel="Delete Collection Modal"
        aria={{
          labelledby: "Delete Collection Modal",
          describedby: "A pop up that allows for the deletion of the collection"
        }}>
          <h2>Delete collection</h2>
          <p>When you delete this collection, your bookmarks will still be saved.</p>
          <div className="flex justify-between mt4">
            <button className="button--text" onClick={close}>Cancel</button>
            <form action={deleteCollectionUrl} method="post">
              <input name="_method" type="hidden" value="delete" />
              <input name="authenticity_token" type="hidden" value={railsCsrfToken()} />
              <button className="button--text" type="submit" value="Delete">Delete</button>
            </form>
          </div>
      </Modal>
    )
  }
}

export default DeleteCollection