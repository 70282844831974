import React, { Component, Fragment } from "react"
import Hamburger from '../../../../images/ic_hamburger.svg'
import Cross from '../../../../images/ic_cross.svg'
import AsOpenable from '../../../../HOC/AsOpenable'
import GuestNavBar from './GuestNavBar'

class GuestNavDrawer extends Component {

  render () {
    const { navigationProps, isOpen, toggle } = this.props  

    return (
      <Fragment>
        <div className="flex justify-end" aria-label="Open the menu" onClick={toggle}>
          <img src={isOpen? Cross : Hamburger}  aria-hidden="true"></img>
        </div>
        <div className="w-100 h-100 relative z-9999 dn-l drawer-height-open perspective">
        { isOpen &&
          <GuestNavBar navigationProps={navigationProps}/>
        }
      </div>
      </Fragment>
    )
  }
}
export default AsOpenable(GuestNavDrawer)