import React from 'react'

const ToastCloseButton = (props) => {
  const { closeToast, onClose } = props

  return (
    <button autoFocus={true}
      className="fal fa-times Toastify__close-button--default Toastify-custom-close-button"
      onClick={closeToast, onClose}
    />
  )
}

export default ToastCloseButton