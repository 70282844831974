class OnPageEvents {

  static addEventListener = (eventName, callback) => {
    document.addEventListener(eventName, callback)
  }

  static dispatchEvent = (eventName, target, detail) => {
    const event = new CustomEvent(eventName, { bubbles: true, detail: detail })
    target.dispatchEvent(event)
  }

  static removeEventListener = (eventName, callback) => {
    document.removeEventListener(eventName, callback)
  }
}

export default OnPageEvents