import React, { Component } from 'react'

export class SkipButton extends Component {

  focusElementWithId = (id) => {
    document.getElementById(`${id}`).focus();
  }

  render() {
    const {elementId, copy} = this.props

    return (
      <button
        className='skip-button'
        onClick={ ()=> { this.focusElementWithId(elementId) }}>
        { copy }
      </button>
    )
  }
}

export default SkipButton