import { useEffect } from 'react'
import withToast from '../../HOC/withToast'

const AddCopyLinkButtonToHeadings = (props) => {
  const { copyUrl, notify } = props

  let copyLink = (elementId, button) => {
    navigator.clipboard.writeText(`${copyUrl}#${elementId}`)
    notify('Copied!', () => { button.focus() })
  }

  useEffect(() => {
    const insertCopyLinkButtons = (event) => {
      let headings = document.querySelectorAll('h3[id], h4[id]')
      headings.forEach(element => { 
        let button = element.appendChild(document.createElement('button'))
        button.classList.add('fas', 'fa-link', 'button__icon--copy--link', 'button__icon--copy--link--subheading')
        button.onclick = () => { copyLink(element.id, button) } 
      })
    }

    window.addEventListener('load', insertCopyLinkButtons);
    return () => {
      window.removeEventListener('load', insertCopyLinkButtons);
    }
  })

  return(null)
}

export default withToast(AddCopyLinkButtonToHeadings)