import React from "react"
import NavLink from "../../NavLink/NavLink"
import cn from "classnames"

const AboutLinks = (props) => {
  const { aboutLinks, customClasses } = props

  return (
    <ul className="list ma0 pa0">
    {
      aboutLinks.map((link) => {
        return (
          <li key={link.text} role="none" className={`${cn({'pb3': link.path === window.location.href})} ${customClasses}`}>
            <NavLink customClasses={`${cn({'active-guest-link': link.path === window.location.href})} fw7 about-nav-drawer-link about-link mr0 pl4 pl0-ns pr0`} link={link} showActiveStyles = {false} />
          </li>
        )
      })
    }
  </ul>
  )
}
export default AboutLinks