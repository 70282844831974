import React, { Component } from 'react'
import collectionIcon  from '../../images/collections-icon.svg'

class CollectionCard extends Component {
  render = () => {
    const { name, path } = this.props

    return(
      <li className='Variable-column-container'>
        <a
          href={ path }
          className='no-underline'
          data-tracking-label='Collection Card Link Followed'
        >
          <div className='card--navigation card__categories--navigation'>
            <p className='Collection__card-title self-center'>{name}</p>
            <div className='card__resources-collection--navigation ml3 pt1'>
              <img
                src={collectionIcon}
                className='mw-none'
                alt=''/>
            </div>
          </div>
        </a>
      </li>
    )
  }
}

export default CollectionCard