import React, {Component} from "react"

class SearchField extends Component {
  state = {
    term: this.props.search_term || undefined
  }

  onTermEdited = (e) => {
    this.setState({ term: e.target.value })
  }

  render = () => {
    const { search_results_path, placeholder } = this.props
    const { term } = this.state
    return (
      <form className="flex" action={search_results_path} method="get" autoComplete="off" className="Search-field w-100">
        <input className="Search-input" type="text" name="terms" value={ term } placeholder={ placeholder } onChange={ this.onTermEdited } autoFocus={true}/>
        <input className="button--contained Search-button" type="submit" value="Search" />
      </form>
    )
  }
}

export default SearchField
