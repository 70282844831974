import React, { Component, Fragment } from "react"
import SameTabNavLink from "./SameTabNavLink"
import NewTabNavLink from "./NewTabNavLink"

class NavLink extends Component {

  activeLinkStyles = (link) => link.isActive ? 'Navigation-link--active' : ''

  render () {
    const { link, showActiveStyles, customClasses } = this.props
    const linkProps = {
      link: link,
      optionalCustomClasses: customClasses ? customClasses : "",
      optionalActiveStyles: showActiveStyles ? this.activeLinkStyles(link) : ""
    }
    return <Fragment>
      { link.openNewTab
        ? <NewTabNavLink {...linkProps} />
        : <SameTabNavLink {...linkProps} />
      }
      </Fragment>
  }
}
export default NavLink