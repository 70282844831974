import React, { Component, Fragment } from 'react'
import NewCollection from '../forms/NewCollection'
import Modal from 'react-modal'

import AsOpenable from '../../HOC/AsOpenable'

Modal.setAppElement('#main-content');

class AddCollection extends Component {
  constructor(props) {
    super(props);
    this.createCollectionRef = React.createRef();
    this.state = {
      newCollectionKeyIndex: 0,
    }
  }

  resetThenClose = () => {
    const { close } = this.props

    this.setState((previousState) => {
      return { newCollectionKeyIndex: previousState.newCollectionKeyIndex + 1 }
    })
    close()
  }

  newCollectionKey = () => {
    const { newCollectionKeyIndex } = this.state

    return `newCollection${newCollectionKeyIndex}`
  }

  render = () => {
    const { open, isOpen, createCollectionUrl } = this.props

    return(
      <Fragment>
        <button
          className="flex Create-collection-button pl0"
          id='create-collection'
          ref={ this.createCollectionRef }
          onClick={ open }>
            <p className="navy mb0 transition-24">Add new collection</p>
            <i className="fas fa-plus fw3 pl3 mt3 self-center transition-24"/>
        </button>
        <Modal
          isOpen={ isOpen }
          onRequestClose={ this.resetThenClose }
          className='Modal'
          overlayClassName='Backdrop'
          contentLabel="Create Collection Modal"
          aria={{
            labelledby: "Create Collection Modal",
            describedby: "A pop up that allows for the creation of a new collection"
          }}>
            <NewCollection
              key= { this.newCollectionKey() }
              close={ this.resetThenClose }
              revertFocus={ this.createCollectionRef }
              createCollectionUrl={ createCollectionUrl }/>
        </Modal>
      </Fragment>
    )
  }
}

export default AsOpenable(AddCollection)