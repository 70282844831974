import React, { Fragment, useRef } from 'react'
import asOpenable from '../../HOC/AsOpenable'
import DeleteCollection from './DeleteCollection'

const DeleteCollectionButton = (props) => {
  const { open, ...passThroughProps } = props

  return (
    <Fragment>
      <button onClick={ open } className="guardsman-red" role="menuitem">
        Delete Collection
      </button>
      <DeleteCollection {...passThroughProps} />
    </Fragment>
  )
}

DeleteCollectionButton.defaultProps = {
  isOpen: false
}

export default asOpenable(DeleteCollectionButton)