import React from "react"

export default function SameTabNavLink(props) {
  const { link, optionalCustomClasses, optionalActiveStyles} = props
  return (
    <a 
      key={ link.text } 
      tabIndex = {0} 
      className={`${optionalCustomClasses} ${optionalActiveStyles} Navigation-link`} 
      href={ link.path }
      data-tracking-label={ link.dataTrackingLabel }>
      { link.text }
    </a>
  )
}