import React, { Component, Fragment } from 'react'
import Empty from '../Empty'
import handleErrors, { checkForRecordInvalid } from '../../helpers/HandleErrors'
import OnPageEvents from '../../helpers/OnPageEvents'

import withToast from '../../HOC/withToast'
import withCollectionApi from '../../HOC/withCollectionApi'

const charLimit = 45

class NewCollection extends Component {
  state = {
    collectionName: '',
    charCount: 0,
    charLimitReached: false,
  }

  revertFocus = () => {
    this.props.revertFocus.current.focus()
  }

  onNameEdited = (e) => {

    // to enforce maxLength on some android browsers
    const name = e.target.value.substring(0, charLimit)

    this.setState({
      collectionName: name,
      charCount: name.length,
      charLimitReached: name.length == charLimit ? true : false,
    })
  }

  characterLimitMessage = () => {
    const { charLimitReached } = this.state

    return charLimitReached ? (
      <p>You have reached the character limit</p>
    ) : <Empty/>
  }

  addCollection = () => {
    const { collectionName } = this.state
    const { createCollection, close, notify, notifyError, revertFocus } = this.props

    createCollection(collectionName)
      .then(this.checkForRecordInvalid)
      .then(handleErrors)
      .then(response => response.json())
      .then((data) => {
        notify('The collection has been created', this.revertFocus)
        OnPageEvents.dispatchEvent('collectionCreated', revertFocus.current, {name: data['name'], path: data['path'] })
      })
      .catch((error) => {
        if(error.message == 'RecordInvalid' || 'Conflict') {
          notifyError(`${collectionName} collection already exists, please try again`, this.revertFocus)
        }
      })

    close()
  }

  render = () => {
    const { collectionName, charCount, charLimitReached } = this.state
    const { close } = this.props
    const characterLimitMessage = this.characterLimitMessage()

    return (
      <Fragment>
        <h2>Add new collection</h2>
        <input
          className='Text-field--input mt3 pl3 navy'
          type='text'
          ref={(input) => input && input.focus()}
          name='collection name'
          value={collectionName}
          placeholder='Collection name'
          onChange={this.onNameEdited}
          maxLength={charLimit}
        />
        <div
          className={
            charLimitReached
              ? 'flex justify-between Collection__limit-reached'
              : ''
          }
        >
          {characterLimitMessage}

          <p className='tr mb4 Collection__char-count'>
            {charCount}/{charLimit}
          </p>
        </div>
        <div className='flex justify-between'>
          <button
            className='button--text'
            onClick={close}
            aria-label='Close Popup'
          >
            CANCEL
          </button>
          <button
            className='button--text'
            onClick={this.addCollection}
            disabled={charCount == 0 ? true : false}
            aria-label={`Create Collection named ${collectionName}`}
          >
            ADD
          </button>
        </div>
      </Fragment>
    )
  }
}

export default withCollectionApi(withToast(NewCollection))