import React from "react"
import NavLink from "../NavLink/NavLink"

export default function ResourceLinks(props) {
  const { resourceLinks } = props
  return (
    <div className="flex items-center">
      {
        resourceLinks.map((link) => {
          return (
            <div key={link.text} className="pb0">
              <NavLink link={link} openNewTab={link.openNewTab} showActiveStyles = {true} />
            </div>
          )
        })
      }
    </div>
  )
}