import React, { Fragment } from "react"

export default function OpenAccountLinksButton(props) {
  const { navigationProps, toggleAccountOptions, accountOptionsShowing } = props
  return <Fragment>
    <button className='button__icon'
      aria-label="Open account options"
      role="menuitem"
      aria-expanded={accountOptionsShowing}
      aria-haspopup="true"
      onClick={toggleAccountOptions}
      key={navigationProps.usersName}
      tabIndex = {0}
      id="account_options_menu"
      >
      <i className='far fa-user-circle f3' aria-hidden="true"></i>
    </button>
  </Fragment>
}
