import React, { Fragment, useRef } from 'react'
import asOpenable from '../../HOC/AsOpenable'
import RenameCollection from './RenameCollection'

const RenameCollectionButton = (props) => {
  const { open, revertFocusRef, ...passThroughProps } = props

  const revertFocus = () => {
    revertFocusRef.current.focus()
  }

  return (
    <Fragment>
      <button onClick={ open } className='navy' role="menuitem">
        Rename Collection
      </button>
      <RenameCollection revertFocus={revertFocus} {...passThroughProps}/>
    </Fragment>
  )
}

RenameCollectionButton.defaultProps = {
  isOpen: false
}

export default asOpenable(RenameCollectionButton)