import React, { useState, useEffect, useRef } from 'react'
import Modal from 'react-modal'
import CharacterLimit from '../forms/CharacterLimit'
import handleErrors, { checkForRecordInvalid } from '../../helpers/HandleErrors'

import OnPageEvents from '../../helpers/OnPageEvents'

import withCollectionApi from '../../HOC/withCollectionApi'
import withToast from '../../HOC/withToast'

Modal.setAppElement('#main-content') // TODO - Michael: find a way we dont have to do this everytime

const RenameCollection = (props) => {
  const { isOpen, close, name, revertFocus } = props
  const [newName, setNewName] = useState(name)
  const [confirmedName, setConfirmedName] = useState(undefined)
  const [lastError, setLastError] = useState(undefined)
  const maxNameLength = props.maxNameLength
  const renameButtonRef = useRef(null)

  useEffect(()=>{
    const { notify } = props

    if(newName === confirmedName) {
      notify('The collection has been renamed', revertFocus)
      OnPageEvents.dispatchEvent('renamedCollection', renameButtonRef.current, { name: confirmedName })
      close()
    }
  }, [confirmedName])

  useEffect(()=>{
    const { notifyError } = props
    if (!lastError){
      return
    }

    if(lastError.message === 'RecordInvalid' || 'Conflict') {
      notifyError(`Collection ${newName} already exists, please try again`, revertFocus)
      close()
    }
    else {
      notifyError(`Oops something went wrong, please try again`, revertFocus)
    }
  }, [lastError])

  const attemptRename = () => {
    const { renameCollection } = props

    renameCollection(newName)
    .then(checkForRecordInvalid)
    .then(handleErrors)
    .then(response => response.json())
    .then((json)=>{
      setConfirmedName(json.name)
    })
    .catch((error) => {
      setLastError(error)
    })
  }

  const handleNameChanged = (event) => {
    setNewName(event.target.value)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      className="Modal pb-1-5rem pb4-ns"
      overlayClassName='Backdrop'
      contentLabel="Rename Collection"
      aria={{
        labelledby: "Rename Collection Modal",
        describedby: "A pop up that allows for the renaming of a collection"
      }}
    >
      <h2>Rename Collection</h2>
      <input type='text' onChange={handleNameChanged} defaultValue={newName} className="Text-field--input mt3 pl3 navy" maxLength={maxNameLength}/>
      <CharacterLimit text={newName} limit={maxNameLength} errorMessage={'You have reached the character limit'} />
      <div className="flex justify-between">
        <button className="button--text" onClick={close}>
          Cancel
        </button>
        <button className="button--text" onClick={attemptRename} ref={renameButtonRef}>
          Rename
        </button>
      </div>
    </Modal>
  )
}

RenameCollection.defaultProps = {
  name: '',
  maxNameLength: 45
}

export default withToast(withCollectionApi(RenameCollection))