import React, { Component, Fragment } from 'react'

class SurveyAlert extends Component {

  state = {
    hideAlert: localStorage.getItem('surveyAlertDismissed') || false
  }

  hideAlert = () => {
    localStorage.setItem('surveyAlertDismissed', true)
    this.setState({hideAlert: true})
}

  render = () => {
    const { hideAlert } = this.state

    if (hideAlert) { return (<Fragment/>) }

    return (
      <div className='bg-mischka-gray-2'>
        <div className='page-width flex justify-between pv3 pr3'>
          <p className='fw4 ma0 copy'>
            Help us build the best platform to support your diversity and inclusion goals - 
            <a tabIndex = {0}
              target='_blank'
              rel='noopener noreferrer'
              className='fw7 underline navy pl1'
              href='https://iesdigital.formstack.com/forms/customer_product_survey'>
              complete our 2 minute survey
              <span className="ScreenReaderOnly">(opens in a new tab)</span>
            </a>
          </p>
          <button className='bn bg-transparent pointer' aria-label="Dismiss alert" onClick={ this.hideAlert }>
            <i className='fal fa-times copy'></i>
          </button>
        </div>
      </div>
    )
  }
}

export default SurveyAlert