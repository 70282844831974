import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ToastCloseButton from '../components/shared/ToastCloseButton'
import handleErrors from '../helpers/HandleErrors'

const asBookmarkable = (WrappedComponent) => {
  return class extends Component {

    state = {
      bookmarked: this.props.bookmarked || false,
      wasFocused: document.activeElement
    }

    bookmark = (resourceId) => {
      this.props.createBookmark(resourceId)
      .then(handleErrors)
      .then(() => { this.setState({ bookmarked: true, wasFocused: document.activeElement }) })
      .then(() => this.notify('Bookmark added'))
      .catch(error => console.error(error))
    }

    unbookmark = (resourceId) => {
      this.props.deleteBookmark(resourceId)
      .then(this.handleErrors)
      .then(() => { this.setState({ bookmarked: false, wasFocused: document.activeElement }) })
      .then(() => this.notify('Bookmark removed'))
      .catch(error => console.error(error))
    }

    //TODO: Michael - find a way to be able to supply this from the component to avoid edgecase logic, maybe using hooks?
    revertFocus = () => {
      const { wasFocused } = this.state

      if(!!wasFocused.closest('.card__link--resources')) {
        this.state.wasFocused.closest('.card__link--resources').focus()
      }
      else{
        wasFocused.focus()
      }
    }

    notify = (message) => toast(message, {
      toastClassName: 'Toastify__toast--default flex items-center',
      position: "bottom-center",
      role: "alert",
      autoClose: 900000,
      closeButton: <ToastCloseButton onClose={this.revertFocus}/>,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      })

    render = () => {
      return (
        <WrappedComponent {...this.props} {...this.state} bookmark={this.bookmark} unbookmark={this.unbookmark} />
      )
    }
  }
}

export default asBookmarkable
