import React, { Component, Fragment } from "react"
import ResourceLinks from "./ResourceLinks"
import AccountLinks from "./AccountLinks"
import UserName from "./UserName"

class NavDrawer extends Component {

  state = { drawerShowing: false }

  toggleDrawer = () => this.setState({ drawerShowing: !this.state.drawerShowing })

  render () {
    const { navigationProps } = this.props
    const { drawerShowing } = this.state
    return <Fragment>
      <div className="flex justify-end">
        <button className='pointer bn bg-titan-white' aria-label="Open the menu" onClick={this.toggleDrawer}>
          <i className={`${drawerShowing? "fal fa-times": "fas fa-bars"} f3 navy pv-0-9375rem pr-0-9375rem`} aria-hidden="true"></i>
        </button>
      </div>
      { this.state.drawerShowing &&
        <div className="w-100 h-100 z-9999 dn-l">
          <aside className="card--navigation card__drawer--navigation z-9999">
            <nav className='flex flex-column'>
              { navigationProps.userLoggedIn ? <UserName navigationProps={navigationProps}/> : "" }
              <ResourceLinks resourceLinks={navigationProps.resourceLinks} />
              <AccountLinks accountLinks={navigationProps.accountLinks} />
            </nav>
          </aside>
        </div>
      }
    </Fragment>
  }
}
export default NavDrawer