import React, { Fragment, useState } from 'react'
import withToast from '../HOC/withToast'

const CopyLinkBtn = (props) => {
  const { copyUrl, notify } = props
  const [hovered, setHovered] = useState(false)

  let copyLinkButton = React.createRef()

  let copyLink = () => {
    navigator.clipboard.writeText(copyUrl)
    notify('Copied!', () => { copyLinkButton.current.focus() })
  }

  return (
    <Fragment>
      <button
        className='fas fa-link button__icon--copy--link button__icon--copy--link--banner'
        onMouseOver={ () => setHovered(true) }
        onMouseOut={ () => setHovered(false)}
        onClick={ () => copyLink() }
        aria-label={ `copy link to clipboard` }
        ref={ copyLinkButton }
      >
        <div 
          tabIndex={ -1 }
          aria-hidden='true'
          role='tooltip'
          className={`${ hovered ? 'tooltip--visible' : 'tooltip--hidden'} tooltip nunito-font`}>
            Copy link to clipboard
          </div>
      </button>
    </Fragment>
  )
}

export default withToast(CopyLinkBtn)