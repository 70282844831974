import React from "react"
import NavLink from "../NavLink/NavLink"

export default function ResourceLinks(props){
  const { resourceLinks } = props
  return (
    <div className="flex flex-column b--silver bb">
    {
      resourceLinks.map((link) => {
        return (
          <div className="pb4" key={link.text}>
            <NavLink link={link} showActiveStyles = {true} />
          </div>
        )
      })
    }
  </div>
  )
}