import React, { useEffect } from 'react'
import Masonry from 'masonry-layout';
import OnPageEvents from '../../helpers/OnPageEvents';

const MasonryGrid = () => {
  let elem = document.querySelector('.grid');
  let msnry = new Masonry( elem, {
    itemSelector: ".masonry-grid__item",
    gutter: 0, 
    horizontalOrder: true
  });

  useEffect(() => {
    const calculateMasonryLayout = (event) => {
      msnry.layout()
    }

    window.addEventListener('load', calculateMasonryLayout);
    OnPageEvents.addEventListener('hideEditorControls', calculateMasonryLayout)

    return () => {
      window.removeEventListener('load', calculateMasonryLayout);
      OnPageEvents.removeEventListener('hideEditorControls', calculateMasonryLayout)
    }
  })

  return(null)
}

export default MasonryGrid

