import React, { Component } from 'react'
import Checkbox from './Checkbox.js'

import withToast from '../../HOC/withToast'
import withCollectionBookmarkApi from '../../HOC/withCollectionBookmarkApi'
import handleErrors from '../../helpers/HandleErrors'

class ManageBookmark extends Component {

  state = {
    collections: {}
  }

  componentDidMount(){
    const { getCollectionDetailsForBookmark, bookmarkId } = this.props
    getCollectionDetailsForBookmark(bookmarkId)
    .then(handleErrors)
    .then(response => response.json())
    .then((data)=>{
      this.setState({ collections: data.collection_details })
    })
  }

  revertFocus = () => {
    this.props.revertFocus.current.focus()
  }

  handleClick = (collection_id) => {
    let collectionsState = this.state.collections
    collectionsState[collection_id].selected = !collectionsState[collection_id].selected
    this.setState({collections: collectionsState})
  }

  updateCollections = () => {
    const { updateCollectionBookmarks, bookmarkId, close, notify } = this.props
    const { collections } = this.state

    updateCollectionBookmarks(bookmarkId, collections)
    .then(handleErrors)
    .then(() => {
      notify('Your changes have been saved', this.revertFocus)
      })
    .then(() => close())
  }

  render() {
    const { collections } = this.state
    const { close } = this.props

    return (
      <div>
        <h2 className='navy'>Manage Bookmark</h2>
        <p className='fw4 navy mb2'>Add or remove this bookmark to any collection.</p>
        <div className='Modal--manage-bookmarks Scrollbar'>
          { Object.keys(collections).map((id, index) => {
            return (
              <Checkbox
              key={ index }
              collection={ collections[id] }
              collection_id={ id }
              handleClick={ this.handleClick }
            />
            );
          }) }
        </div>
        <div className="flex justify-between mt-3rem">
          <button
            className='button--text'
            onClick={ close }
            aria-label="Close Popup">
              CANCEL
          </button>
          <button
            className='button--text'
            onClick={ this.updateCollections }>
              SAVE
          </button>
        </div>
      </div>
    )
  }
}

export default withCollectionBookmarkApi(withToast(ManageBookmark))