import React, { useState, useEffect, Fragment } from 'react'
import Bookmark from './Bookmark.js'
import ManageBookmarkBtn from './ManageBookmarkBtn'
import OnPageEvents from '../helpers/OnPageEvents'

const CollectionBookmarkControls = (props) => {
  const [areCollectionsAvailable, setAreCollectionsAvailable] = useState(props.areCollectionsAvailable || false)

  useEffect(() => {
    function handleCollectionCreated (_) {
      setAreCollectionsAvailable(true)
    }

    OnPageEvents.addEventListener('collectionCreated', handleCollectionCreated)

    return () => {
      OnPageEvents.removeEventListener('collectionCreated', handleCollectionCreated)
    }
  })

  return (
    <Fragment>
      {areCollectionsAvailable && <ManageBookmarkBtn { ...props } /> }
      <Bookmark { ...props } />
    </Fragment>
  )
}

export default CollectionBookmarkControls
