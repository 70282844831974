import React, {Component} from 'react'

import { toast } from 'react-toastify'
import ToastCloseButton from '../components/shared/ToastCloseButton'

const withToast = (WrappedComponent) =>{
  return class extends Component {

    notify = (message, closeButton) => toast(message, {
      toastClassName: 'Toastify__toast--default flex items-center',
      position: "bottom-center",
      role: "alert",
      autoClose: 900000,
      closeButton: <ToastCloseButton onClose={closeButton}/>,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })

    notifyError = (message, closeButton) => toast(message, {
      toastClassName: 'Toastify__toast--default Toastify__toast--error flex items-center',
      position: "bottom-center",
      role: "alert",
      autoClose: 900000,
      closeButton: <ToastCloseButton onClose={closeButton}/>,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressClassName: 'Toastify__progress-bar--error'
    })

    render = () => {
      return (
        <WrappedComponent notify={this.notify} notifyError={this.notifyError} {...this.props}/>
      )
    }
  }
}

export default withToast